var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "el-button",
        {
          staticStyle: { "margin-bottom": "10px" },
          attrs: { type: "primary", size: "mini", icon: "el-icon-plus" },
          on: { click: _vm.submitWorkOrder },
        },
        [_vm._v("提交工单")]
      ),
      _vm._v(" "),
      _c(
        "el-table",
        {
          attrs: {
            data: _vm.pageInfo.list,
            border: "",
            fit: "",
            "highlight-current-row": "",
          },
        },
        [
          _c("el-table-column", { attrs: { prop: "id", label: "工单id" } }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { prop: "title", label: "工单标题" },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { prop: "contactPhone", label: "创建人" },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { prop: "description", label: "工单描述" },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { prop: "description", label: "工单类型" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.type === 1
                      ? _c("span", [_vm._v("开通应用")])
                      : _vm._e(),
                    _vm._v(" "),
                    scope.row.type === 2
                      ? _c("span", [_vm._v("平台反馈")])
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { prop: "description", label: "工单状态" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.status === 1
                      ? _c(
                          "el-tag",
                          {
                            attrs: {
                              type: "warning",
                              "disable-transitions": "",
                            },
                          },
                          [_vm._v("待处理")]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    scope.row.status === 2
                      ? _c(
                          "el-tag",
                          {
                            attrs: {
                              type: "success",
                              "disable-transitions": "",
                            },
                          },
                          [_vm._v("已处理")]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    scope.row.status === 3
                      ? _c(
                          "el-tag",
                          {
                            attrs: {
                              type: "danger",
                              "disable-transitions": "",
                            },
                          },
                          [_vm._v("已关闭")]
                        )
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "操作", width: "200" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text", size: "mini" },
                        on: {
                          click: function ($event) {
                            return _vm.onDetail(scope.row)
                          },
                        },
                      },
                      [_vm._v("查看详情")]
                    ),
                    _vm._v(" "),
                    scope.row.status == 1
                      ? _c(
                          "el-button",
                          {
                            attrs: { type: "text", size: "mini" },
                            on: {
                              click: function ($event) {
                                return _vm.updateStatus(scope.row, 3)
                              },
                            },
                          },
                          [_c("el-tag", [_vm._v("取消")])],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    scope.row.status == 3
                      ? _c(
                          "el-button",
                          {
                            attrs: { type: "text", size: "mini" },
                            on: {
                              click: function ($event) {
                                return _vm.deleteRow(scope.row)
                              },
                            },
                          },
                          [
                            _c("el-tag", { attrs: { type: "danger" } }, [
                              _vm._v("删除"),
                            ]),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("el-pagination", {
        staticStyle: { "margin-top": "5px" },
        attrs: {
          background: "",
          "current-page": _vm.searchFormData.pageIndex,
          "page-size": _vm.searchFormData.pageSize,
          "page-sizes": [5, 10, 20, 40],
          total: _vm.pageInfo.total,
          layout: "total, sizes, prev, pager, next",
        },
        on: {
          "size-change": _vm.onSizeChange,
          "current-change": _vm.onPageIndexChange,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }