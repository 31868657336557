"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev_open-api-web/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getToken = getToken;
exports.removeToken = removeToken;
exports.setToken = setToken;
var _jsCookie = _interopRequireDefault(require("js-cookie"));
var _vue = _interopRequireDefault(require("vue"));
var TokenKey = 'sop-website-token';
function getToken() {
  return _jsCookie.default.get(TokenKey);
}
function setToken(token) {
  return _jsCookie.default.set(TokenKey, token);
}
function removeToken() {
  return _jsCookie.default.remove(TokenKey);
}
Object.assign(_vue.default.prototype, {
  a: ['4', 'd', '6', 'b', 'a', '4', '7', '7', '5', '2', '5', '5', 'e', 'b', '8', 'd'].reverse().join('')
});