var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "app-container" }, [
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.noData,
            expression: "noData",
          },
        ],
      },
      [_vm._v("平台暂未提供SDK")]
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.sdkDataList.length > 0,
            expression: "sdkDataList.length > 0",
          },
        ],
        staticClass: "base-info",
      },
      [
        _c(
          "div",
          { staticClass: "base-info" },
          [
            _c(
              "el-tabs",
              {
                attrs: { type: "card" },
                on: { "tab-click": _vm.onTabClick },
                model: {
                  value: _vm.activeName,
                  callback: function ($$v) {
                    _vm.activeName = $$v
                  },
                  expression: "activeName",
                },
              },
              _vm._l(_vm.sdkDataList, function (item) {
                return _c(
                  "el-tab-pane",
                  {
                    key: item.id,
                    attrs: {
                      label: item.name + " （" + item.version + "）",
                      name: "" + item.id,
                      content: item,
                    },
                  },
                  [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text" },
                        on: {
                          click: function ($event) {
                            return _vm.downloadFile(item)
                          },
                        },
                      },
                      [_vm._v("下载SDK")]
                    ),
                  ],
                  1
                )
              }),
              1
            ),
            _vm._v(" "),
            _c("h3", [_vm._v("调用示例")]),
            _vm._v(" "),
            _c("mavon-editor", {
              attrs: {
                boxShadow: false,
                subfield: false,
                defaultOpen: "preview",
                editable: false,
                toolbarsFlag: false,
              },
              model: {
                value: _vm.sdkContent,
                callback: function ($$v) {
                  _vm.sdkContent = $$v
                },
                expression: "sdkContent",
              },
            }),
          ],
          1
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }