"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  data: function data() {
    return {
      tableData: []
    };
  },
  created: function created() {
    var _this = this;
    console.log(JSON.stringify(this.tableData));
    this.getFile('static/code.json?q=' + new Date().getTime(), function (json) {
      _this.tableData = json;
    });
  }
};
exports.default = _default;