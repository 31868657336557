"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _mavonEditor = require("mavon-editor");
require("mavon-editor/dist/css/index.css");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    mavonEditor: _mavonEditor.mavonEditor
  },
  data: function data() {
    return {
      noData: false,
      sdkDataList: [],
      sdkConfigs: [],
      activeName: '',
      sdkContent: ''
    };
  },
  created: function created() {
    this.loadIspSdk();
  },
  methods: {
    loadIspSdk: function loadIspSdk() {
      var _this = this;
      this.get('/portal/isv/listSdk', {}, function (resp) {
        _this.sdkDataList = resp.data;
        _this.noData = _this.sdkDataList.length === 0;
        if (_this.sdkDataList.length > 0) {
          var item = _this.sdkDataList[0];
          _this.activeName = "".concat(item.id);
          _this.loadSdkFile(item);
        }
      });
    },
    downloadFile: function downloadFile(row) {
      window.open(row.content);
    },
    onTabClick: function onTabClick(tab) {
      var item = tab.$attrs.content;
      this.loadSdkFile(item);
    },
    loadSdkFile: function loadSdkFile(item) {
      this.sdkContent = item.extContent || '未提供示例';
    }
  }
};
exports.default = _default;