"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.function.name");
require("core-js/modules/web.dom.iterable");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = {
  name: 'ParameterTableEdit',
  props: {
    data: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    tree: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    buildParamRules: function buildParamRules(row) {
      var rules = [];
      if (row.required && row.type !== 'file') {
        rules.push({
          required: true,
          message: '请填写参数值',
          trigger: 'blur'
        });
      }
      var max = parseInt(row.maxLength);
      if (max) {
        rules.push({
          max: max,
          message: "\u957F\u5EA6\u4E0D\u8D85\u8FC7 ".concat(max, " \u4E2A\u5B57\u7B26"),
          trigger: 'blur'
        });
      }
      return {
        paramExample: rules
      };
    },
    onSelectFile: function onSelectFile(f, fileList, row) {
      var files = [];
      fileList.forEach(function (file) {
        var rawFile = file.raw;
        files.push(rawFile);
      });
      row.__file__ = {
        name: row.name,
        files: files
      };
    }
  }
};
exports.default = _default2;