"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev_open-api-web/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.replace");
require("core-js/modules/es7.object.values");
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.object.keys");
require("core-js/modules/es6.function.name");
var _ParameterTable = _interopRequireDefault(require("@/components/ParameterTable"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = {
  name: 'DocView',
  components: {
    ParameterTable: _ParameterTable.default
  },
  props: {
    item: {
      type: Object,
      default: function _default() {}
    },
    urlProd: {
      type: String,
      default: ''
    }
  },
  data: function data() {
    return {
      active: 'info',
      baseInfoCellStyle: function baseInfoCellStyle(row) {
        if (row.columnIndex === 0 || row.columnIndex === 2) {
          return {
            padding: '5px 0',
            background: '#f5f7fa'
          };
        } else {
          return {
            padding: '5px 0'
          };
        }
      },
      commonParams: [],
      commonResult: [],
      docBaseInfoData: [],
      docInfo: {
        summary: '',
        httpMethodList: [],
        requestParameters: [],
        responseParameters: [],
        bizCodes: []
      },
      responseSuccessExample: {},
      responseErrorExample: {
        error_response: {
          request_id: '0d27836fcac345729176359388aeeb74',
          code: '40004',
          msg: '业务处理失败',
          sub_code: 'isv.name-error',
          sub_msg: '姓名错误'
        }
      }
    };
  },
  watch: {
    item: function item(newVal) {
      this.initItem(newVal);
    }
  },
  created: function created() {
    var _this = this;
    this.getFile('static/params.json', function (json) {
      _this.commonParams = json.commonParams;
      _this.commonResult = json.commonResult;
    });
  },
  methods: {
    initItem: function initItem(item) {
      this.setData(item);
    },
    setData: function setData(data) {
      this.docInfo = data;
      this.createResponseExample(data);
    },
    createResponseExample: function createResponseExample(data) {
      var ret = {};
      var responseData = {
        request_id: '4b8e7ca9cbcb448491df2f0120e49b9d',
        code: '10000',
        msg: 'success'
      };
      ret[this.getResponseNodeName()] = responseData;
      var bizRet = this.createExample(data.responseParameters);
      for (var key in bizRet) {
        responseData[key] = bizRet[key];
      }
      this.responseSuccessExample = ret;
    },
    createExample: function createExample(params) {
      var responseJson = {};
      for (var i = 0; i < params.length; i++) {
        var row = params[i];
        if (row.in === 'header') {
          continue;
        }
        var val = void 0;
        // 如果有子节点
        if (row.refs && row.refs.length > 0) {
          var childrenValue = this.createExample(row.refs);
          // 如果是数组
          if (row.type === 'array') {
            val = [childrenValue];
          } else {
            val = childrenValue;
          }
        } else {
          // 单值
          val = row.paramExample;
        }
        responseJson[row.name] = val;
      }
      var isOneArray = Object.keys(responseJson).length === 1 && this.isArray(Object.values(responseJson)[0]);
      if (isOneArray) {
        return Object.values(responseJson)[0];
      }
      return responseJson;
    },
    getResponseNodeName: function getResponseNodeName() {
      var name = this.docInfo.name;
      return name.replace(/\./g, '_') + '_response';
    }
  }
};
exports.default = _default2;