"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _mavonEditor = require("mavon-editor");
require("mavon-editor/dist/css/index.css");
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    mavonEditor: _mavonEditor.mavonEditor
  },
  data: function data() {
    return {
      content: ''
    };
  },
  created: function created() {
    var _this = this;
    this.getFile('static/doc/common/sign.md', function (content) {
      _this.content = content;
    });
  }
};
exports.default = _default;