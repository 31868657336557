"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev_open-api-web/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _jsMd = _interopRequireDefault(require("js-md5"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  data: function data() {
    var _this = this;
    var validatePassword2 = function validatePassword2(rule, value, callback) {
      if (value !== _this.updatePwdData.password) {
        callback(new Error('两次密码不一致'));
      } else {
        callback();
      }
    };
    return {
      updatePwdData: {
        oldPassword: '',
        password: '',
        password2: ''
      },
      updatePwdRules: {
        oldPassword: [{
          required: true,
          message: '请输入旧密码',
          trigger: 'blur'
        }],
        password: [{
          required: true,
          message: '请输入新密码',
          trigger: 'blur'
        }],
        password2: [{
          required: true,
          trigger: 'blur',
          validator: validatePassword2
        }]
      }
    };
  },
  methods: {
    handleUpdate: function handleUpdate() {
      var _this2 = this;
      this.$refs.updatePwdForm.validate(function (valid) {
        if (valid) {
          var data = {};
          Object.assign(data, _this2.updatePwdData);
          data.oldPassword = (0, _jsMd.default)(data.oldPassword);
          data.password = (0, _jsMd.default)(data.password);
          _this2.post('/portal/isv/updatePassword', data, function (resp) {
            alert('修改成功，请重新登录');
            this.logout();
          });
        }
      });
    }
  }
};
exports.default = _default;