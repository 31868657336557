"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _email = require("@/utils/email");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'FindPassword',
  data: function data() {
    return {
      findPasswordFormData: {
        username: ''
      },
      findPasswordRules: {
        username: [{
          required: true,
          message: '请输入登录邮箱',
          trigger: 'blur'
        }, {
          type: 'email',
          message: '请输入正确的邮箱地址',
          trigger: 'blur'
        }]
      },
      submited: false,
      loading: false
    };
  },
  created: function created() {},
  methods: {
    onResetPassword: function onResetPassword() {
      var _this = this;
      this.$refs.findPasswordForm.validate(function (valid) {
        if (valid) {
          _this.doSubmit();
        }
      });
    },
    goEmailSite: function goEmailSite() {
      var email = this.findPasswordFormData.username;
      (0, _email.processEmail)(email, function (url) {
        if (url) {
          location = url;
        }
      });
    },
    onCaptchaSuccess: function onCaptchaSuccess(params) {
      this.doSubmit(function (data) {
        data.captcha = params;
      });
    },
    doSubmit: function doSubmit(callback) {
      var _this2 = this;
      var data = Object.assign({}, this.findPasswordFormData);
      callback && callback.call(this, data);
      this.get('nologin.password.find', data, function (resp) {
        _this2.submited = true;
      });
    },
    useVerify: function useVerify() {
      this.$refs.verify.show();
    }
  }
};
exports.default = _default;