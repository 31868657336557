var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-table",
    {
      attrs: {
        data: _vm.data,
        border: "",
        "row-key": "id",
        "default-expand-all": "",
        "tree-props": { children: "refs", hasChildren: "hasChildren" },
        "cell-style": _vm.cellStyleSmall(),
        "header-cell-style": _vm.headCellStyleSmall(),
        "empty-text": "无参数",
      },
    },
    [
      _c("el-table-column", {
        attrs: { prop: "name", label: "名称", width: "250" },
      }),
      _vm._v(" "),
      _c("el-table-column", {
        attrs: { prop: "type", label: "类型", width: "100" },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (scope) {
              return [
                _c("span", [_vm._v(_vm._s(scope.row.type))]),
                _vm._v(" "),
                _c(
                  "span",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value:
                          scope.row.type === "array" && scope.row.elementType,
                        expression:
                          "scope.row.type === 'array' && scope.row.elementType",
                      },
                    ],
                  },
                  [
                    _c(
                      "el-tooltip",
                      {
                        attrs: {
                          effect: "dark",
                          content: "元素类型：" + scope.row.elementType,
                          placement: "top",
                        },
                      },
                      [_c("i", { staticClass: "el-icon-info" })]
                    ),
                  ],
                  1
                ),
              ]
            },
          },
        ]),
      }),
      _vm._v(" "),
      _c("el-table-column", {
        attrs: { prop: "required", label: "必须", width: "60" },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (scope) {
              return [
                _c("span", { class: scope.row.required ? "danger" : "" }, [
                  _vm._v(_vm._s(scope.row.required ? "是" : "否")),
                ]),
              ]
            },
          },
        ]),
      }),
      _vm._v(" "),
      _c("el-table-column", {
        attrs: { prop: "maxLength", label: "最大长度" },
      }),
      _vm._v(" "),
      _c("el-table-column", { attrs: { prop: "description", label: "描述" } }),
      _vm._v(" "),
      _c("el-table-column", {
        attrs: { prop: "paramExample", label: "示例值" },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (scope) {
              return [
                scope.row.type === "enum"
                  ? _c("div", [
                      _vm._v(
                        "\n        " +
                          _vm._s((scope.row.enums || []).join("、")) +
                          "\n      "
                      ),
                    ])
                  : _c("div", [
                      _vm._v(
                        "\n        " +
                          _vm._s(scope.row.paramExample) +
                          "\n      "
                      ),
                    ]),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }