"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom.iterable");
var _auth = require("@/utils/auth");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  data: function data() {
    return {
      isvInfo: {
        appId: "",
        isUploadPublicKey: 0
      },
      keys: {
        isUploadPublicKey: 0,
        publicKeyIsv: "",
        publicKeyPlatform: "",
        env: 0
      },
      keysBtnLoading: false,
      keysDlgViewShow: false,
      keysUploadDlgShow: false
    };
  },
  created: function created() {
    var _this = this;
    if (this.$route.query.token) {
      sessionStorage.setItem("token", this.$route.query.token);
      this.get("".concat(process.env.VUE_APP_IAM_URL, "/api/v1/users/me/info"), {
        token: this.$route.query.token
      }, function (resp) {
        sessionStorage.setItem("userInfo", JSON.stringify(resp));
        (0, _auth.setToken)(resp.data);
        _this.loadIsvInfo();
      });
    } else {
      this.loadIsvInfo();
    }
  },
  methods: {
    loadIsvInfo: function loadIsvInfo() {
      var _this2 = this;
      this.get("/portal/isv/getIsvPortal", {}, function (resp) {
        _this2.isvInfo = resp.data;
      });
    },
    showKeys: function showKeys(item) {
      Object.assign(this.keys, {
        isUploadPublicKey: item.isUploadPublicKey
      });
      if (!this.keys.isUploadPublicKey) {
        this.keys.publicKeyIsv = "";
        this.keysUploadDlgShow = true;
      } else {
        this.loadPublicKey();
      }
    },
    reUploadPublicKey: function reUploadPublicKey() {
      this.keysDlgViewShow = false;
      this.keys.isUploadPublicKey = 0;
      this.keys.publicKeyIsv = "";
      this.keysUploadDlgShow = true;
    },
    loadPublicKey: function loadPublicKey() {
      var _this3 = this;
      this.get("/portal/isv/getPublicKey", {}, function (resp) {
        _this3.showPublicKey(resp.data);
      });
    },
    showPublicKey: function showPublicKey(data) {
      Object.assign(this.keys, data);
      this.keysDlgViewShow = true;
    },
    onUploadPublicKey: function onUploadPublicKey() {
      var _this4 = this;
      this.$refs.keysForm.validate(function (valid) {
        if (valid) {
          _this4.keysBtnLoading = true;
          _this4.post("/portal/isv/uploadPublicKey", {
            publicKeyIsv: _this4.keys.publicKeyIsv
          }, function (resp) {
            _this4.keysBtnLoading = false;
            _this4.keysUploadDlgShow = false;
            _this4.showPublicKey(resp.data);
            _this4.loadIsvInfo();
          }, function (resp) {
            _this4.keysBtnLoading = false;
            _this4.tipError(resp.msg);
          });
        }
      });
    }
  }
};
exports.default = _default;