"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev_open-api-web/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _jsMd = _interopRequireDefault(require("js-md5"));
var _auth = require("@/utils/auth");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: "Login",
  data: function data() {
    var validateUsername = function validateUsername(rule, value, callback) {
      if (value.length === 0) {
        callback(new Error("请输入账号"));
      } else {
        callback();
      }
    };
    var validatePassword = function validatePassword(rule, value, callback) {
      if (value.length === 0) {
        callback(new Error("请输入密码"));
      } else {
        callback();
      }
    };
    return {
      loginForm: {
        account: "",
        password: ""
      },
      loginRules: {
        account: [{
          required: true,
          trigger: "blur",
          validator: validateUsername
        }],
        password: [{
          required: true,
          trigger: "blur",
          validator: validatePassword
        }]
      },
      loading: false,
      passwordType: "password",
      redirect: undefined
    };
  },
  watch: {
    $route: {
      handler: function handler(route) {
        this.redirect = route.query && route.query.redirect;
      },
      immediate: true
    }
  },
  created: function created() {
    (0, _auth.removeToken)();
  },
  methods: {
    onReg: function onReg() {
      this.$router.push({
        path: "/isvReg"
      });
    },
    showPwd: function showPwd() {
      var _this = this;
      if (this.passwordType === "password") {
        this.passwordType = "";
      } else {
        this.passwordType = "password";
      }
      this.$nextTick(function () {
        _this.$refs.password.focus();
      });
    },
    handleLogin: function handleLogin() {
      var _this2 = this;
      this.$refs.loginForm.validate(function (valid) {
        if (valid) {
          _this2.doSubmit();
        }
      });
    },
    onCaptchaSuccess: function onCaptchaSuccess(params) {
      this.doSubmit(function (data) {
        data.captcha = params;
      });
    },
    doSubmit: function doSubmit(callback) {
      var data = this.loginForm;
      var postData = {
        account: data.account,
        password: data.password
      };
      callback && callback.call(this, postData);
      this.post("".concat(process.env.VUE_APP_IAM_URL, "/api/v1/login/account"), postData, function (resp) {
        console.log(resp, "dd");
        sessionStorage.setItem("userInfo", JSON.stringify(resp));
        sessionStorage.setItem("token", resp.data.token);
        (0, _auth.setToken)(resp.data);
        this.goRoute(this.redirect || "/dashboard");
      });
    },
    useVerify: function useVerify() {
      this.$refs.verify.show();
    }
  }
};
exports.default = _default;