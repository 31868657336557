"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev_open-api-web/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
require("core-js/modules/es6.regexp.to-string");
var _typeof2 = _interopRequireDefault(require("/var/lib/jenkins/workspace/dev_open-api-web/node_modules/@babel/runtime-corejs2/helpers/typeof.js"));
require("core-js/modules/es6.regexp.replace");
require("core-js/modules/es6.array.sort");
require("core-js/modules/es6.function.name");
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.string.starts-with");
require("core-js/modules/es6.string.ends-with");
var _vue = _interopRequireDefault(require("vue"));
var _auth = require("./auth");
var _needle = _interopRequireDefault(require("needle"));
var _jsMd = _interopRequireDefault(require("js-md5"));
var _axios = _interopRequireDefault(require("axios"));
/*
注册全局方法
 */

// import ClipboardJS from 'clipboard'

var baseURL = process.env.VUE_APP_BASE_API;
var OPC_USER_TYPE_KEY = "sop-user-type";

// 创建axios实例
var client = _axios.default.create({
  baseURL: baseURL,
  // api 的 base_url
  timeout: 60000 // 请求超时时间,60秒
});

Object.assign(_vue.default.prototype, {
  /**
   * GET请求接口
   * @param uri uri
   * @param data 请求数据
   * @param callback 成功时回调
   * @param errorCallback 失败时回调
   */
  get: function get(uri, data, callback, errorCallback) {
    console.log("uri", "".concat(baseURL).concat(uri), data);
    var that = this;
    _needle.default.request("GET", uri.indexOf("https://") !== -1 || uri.indexOf("http://") !== -1 ? uri : baseURL + uri, data, {
      // 设置header
      headers: {
        token: "Bearer ".concat(sessionStorage.getItem("token")),
        Authorization: "Bearer ".concat(sessionStorage.getItem("token"))
      }
    }, function (error, response) {
      that.doResponse(error, response, callback, errorCallback);
    });
  },
  $put: function $put(uri, data, callback, errorCallback) {
    var that = this;
    if (uri.indexOf("https://") !== -1 && uri.indexOf("http://") !== -1) {
      if (!uri.endsWith("/")) {
        uri = uri + "/";
      }
      if (!uri.startsWith("/")) {
        uri = "/" + uri;
      }
    }
    client.put(uri, data).then(function (response) {
      var resp = response.data;
      console.log(response, "response");
      var code = resp.code;
      if (!code || code === "-9") {
        that.$message.error(resp.msg || "系统错误");
        return;
      }
      if (code === "-100" || code === "18" || code === "21") {
        // 未登录
        that.logout();
        return;
      }
      if (code === "0" || code === 200 || code === "200") {
        // 成功
        callback && callback.call(that, resp);
      } else {
        that.$message.error(resp.msg);
      }
    }).catch(function (error) {
      console.error("err" + error); // for debug
      errorCallback && errorCallback(error);
      that.$message.error(error.message);
    });
  },
  $delete: function $delete(uri, data, callback, errorCallback) {
    var that = this;
    _needle.default.request("DELETE", uri.indexOf("https://") !== -1 || uri.indexOf("http://") !== -1 ? uri : baseURL + uri, data, {
      // 设置header
      headers: {
        token: (0, _auth.getToken)(),
        Authorization: "Bearer ".concat(JSON.parse((0, _auth.getToken)()).token)
      }
    }, function (error, response) {
      that.doResponse(error, response, callback, errorCallback);
    });
  },
  /**
   * 请求接口
   * @param uri uri
   * @param data 请求数据
   * @param callback 成功时回调
   * @param errorCallback 失败时回调
   */
  post: function post(uri, data, callback, errorCallback) {
    var that = this;
    _needle.default.request("POST", uri.indexOf("https://") !== -1 || uri.indexOf("http://") !== -1 ? uri : baseURL + uri, data, {
      // 指定这一句即可
      json: true,
      headers: {
        token: (0, _auth.getToken)(),
        "x-userpool-id": process.env.VUE_APP_USER_POOL,
        Authorization: "Bearer ".concat(sessionStorage.getItem("token"))
      }
    }, function (error, response) {
      that.doResponse(error, response, callback, errorCallback);
    });
  },
  request: function request(method, uri, data, headers, isJson, isForm, files, callback) {
    // 如果是文件上传，使用axios，needle上传文件不完美，不支持一个name对应多个文件
    if (files && files.length > 0) {
      this.doMultipart(uri, data, files, headers, callback);
      return;
    }
    var that = this;
    if (isForm) {
      headers["Content-Type"] = "application/x-www-form-urlencoded";
    }
    _needle.default.request(method, uri.indexOf("https://") !== -1 || uri.indexOf("http://") !== -1 ? uri : baseURL + uri, data, {
      // 设置header
      headers: headers,
      json: isJson
    }, function (error, response) {
      callback.call(that, error, response);
    });
  },
  doMultipart: function doMultipart(uri, data, files, headers, callback) {
    var that = this;
    var formData = new FormData();
    files.forEach(function (fileConfig) {
      fileConfig.files.forEach(function (file) {
        formData.append(fileConfig.name, file);
      });
    });
    for (var name in data) {
      formData.append(name, data[name]);
    }
    client.post(uri, formData, {
      headers: headers
    }).then(function (response) {
      callback.call(that, null, response);
    }).catch(function (error) {
      callback.call(that, error, null);
    });
  },
  doResponse: function doResponse(error, response, callback, errorCallback) {
    // 成功
    if (!error && response.statusCode === 200) {
      var resp = response.body;
      var code = resp.code;
      if (code === "0" || code == 200) {
        // 成功
        callback && callback.call(this, resp);
      } else {
        this.$message.error(resp.msg || "请求异常，请查看日志");
        errorCallback && errorCallback.call(this, resp);
      }
    } else {
      this.$message.error("请求异常，请查看日志");
    }
  },
  addRole: function addRole(callback) {
    var _this = this;
    var that = this;
    this.$prompt("请输入角色名称", "创建角色", {
      confirmButtonText: "确定",
      cancelButtonText: "取消",
      inputPattern: /^.{1,64}$/,
      inputErrorMessage: "不能为空且长度在64以内"
    }).then(function (_ref) {
      var value = _ref.value;
      _this.get("isp.role.add", {
        roleName: value
      }, function (resp) {
        var data = resp.data;
        callback && callback.call(that, data.roleId, data.roleList);
      });
    }).catch(function () {});
  },
  loadRole: function loadRole(callback) {
    var _this2 = this;
    this.get("isp.role.list", {}, function (resp) {
      callback && callback.call(_this2, resp.data);
    });
  },
  buildSign: function buildSign(postData) {
    var paramNames = [];
    for (var key in postData) {
      paramNames.push(key);
    }
    paramNames.sort();
    var paramNameValue = [];
    for (var i = 0, len = paramNames.length; i < len; i++) {
      var paramName = paramNames[i];
      var value = postData[paramName];
      if (value) {
        paramNameValue.push(paramName);
        paramNameValue.push(value);
      }
    }
    var secret = this.b;
    var source = secret + paramNameValue.join("") + secret;
    return (0, _jsMd.default)(source).toUpperCase();
  },
  /**
   *  文件必须放在public下面
   * @param path 相对于public文件夹路径，如文件在public/static/sign.md，填：static/sign.md
   * @param callback 回调函数，函数参数是文件内容
   */
  getFile: function getFile(path, callback) {
    _axios.default.get(path).then(function (response) {
      callback.call(this, response.data);
    });
  },
  /**
   * ajax请求，并下载文件
   * @param uri 请求path
   * @param params 请求参数，json格式
   * @param filename 文件名称
   */
  downloadFile: function downloadFile(uri, params, filename) {
    client.post(uri, {
      data: encodeURIComponent(JSON.stringify(params)),
      access_token: (0, _auth.getToken)()
    }).then(function (response) {
      var url = window.URL.createObjectURL(new Blob([response.data]));
      var link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", filename);
      document.body.appendChild(link);
      link.click();
    });
  },
  /**
   * tip，使用方式：this.tip('操作成功')，this.tip('错误', 'error')
   * @param msg 内容
   * @param type success / info / warning / error
   */
  tip: function tip(msg, type) {
    this.$message({
      message: msg,
      type: type || "success"
    });
  },
  tipSuccess: function tipSuccess(msg) {
    this.tip(msg, "success");
  },
  tipError: function tipError(msg) {
    this.tip(msg, "error");
  },
  tipInfo: function tipInfo(msg) {
    this.tip(msg, "info");
  },
  /**
   * 提醒框
   * @param msg 消息
   * @param okHandler 成功回调
   * @param cancelHandler
   */
  confirm: function confirm(msg, okHandler, cancelHandler) {
    var that = this;
    this.$confirm(msg, "提示", {
      confirmButtonText: "确定",
      cancelButtonText: "取消",
      type: "warning",
      beforeClose: function beforeClose(action, instance, done) {
        if (action === "confirm") {
          okHandler.call(that, done);
        } else if (action === "cancel") {
          if (cancelHandler) {
            cancelHandler.call(that, done);
          } else {
            done();
          }
        } else {
          done();
        }
      }
    }).catch(function () {});
  },
  /**
   * 提示框
   * <pre>
   * this.alert('注册成功', '提示', function() {
      this.goRoute(`/login`)
     })
   * </pre>
   * @param msg
   * @param title
   * @param callback
   */
  alert: function alert(msg, title, _callback) {
    var that = this;
    this.$alert(msg, title || "提示", {
      confirmButtonText: "确定",
      callback: function callback(action) {
        _callback && _callback.call(that, action);
      }
    });
  },
  /**
   * 重置表单
   * @param formName 表单元素的ref
   */
  resetForm: function resetForm(formName) {
    var frm = this.$refs[formName];
    frm && frm.resetFields();
  },
  logout: function logout() {
    this.get("/portal/common/logout", {}, function (resp) {}, function (resp) {});
    this.goLogin();
  },
  goLogin: function goLogin() {
    (0, _auth.removeToken)();
    this.$router.replace({
      path: "/login"
    });
  },
  goRoute: function goRoute(path) {
    this.$router.push({
      path: path
    });
  },
  /**
   * array转tree，必须要有id,parentId属性
   * @param arr 数组
   * @param parentId 父节点id，第一次调用传0
   * @returns {Array} 返回树array
   */
  convertTree: function convertTree(arr, parentId) {
    var _this3 = this;
    if (!arr) {
      return [];
    }
    // arr是返回的数据parentId父id
    var temp = [];
    var treeArr = arr;
    treeArr.forEach(function (item, index) {
      if (item.parentId === parentId) {
        // 递归调用此函数
        treeArr[index].children = _this3.convertTree(treeArr, treeArr[index].id);
        temp.push(treeArr[index]);
      }
    });
    return temp;
  },
  setAttr: function setAttr(key, val) {
    localStorage.setItem(key, val);
  },
  getAttr: function getAttr(key) {
    return localStorage.getItem(key);
  },
  setUserType: function setUserType(type) {
    this.setAttr(OPC_USER_TYPE_KEY, type);
  },
  /**
   * 是否是isp用户
   * @returns {boolean}
   */
  isIsp: function isIsp() {
    return this.getAttr(OPC_USER_TYPE_KEY) === "1";
  },
  isIsv: function isIsv() {
    return this.getAttr(OPC_USER_TYPE_KEY) === "2";
  },
  cellStyleSmall: function cellStyleSmall() {
    return {
      padding: "5px 0"
    };
  },
  headCellStyleSmall: function headCellStyleSmall() {
    return {
      padding: "5px 0"
    };
  },
  // initCopy: function() {
  //   const _this = this
  //   const clipboard = new ClipboardJS('.copyBtn')
  //   clipboard.on('success', function() {
  //     _this.tipSuccess('复制成功')
  //   })
  //   this.clipboard = clipboard
  // },
  // cleanCopy: function() {
  //   if (this.clipboard) {
  //     this.clipboard.destroy()
  //   }
  // },
  parseJSON: function parseJSON(str, callback, errorCallback) {
    var isJson = false;
    if (typeof str === "string") {
      try {
        var obj = JSON.parse(str);
        isJson = (0, _typeof2.default)(obj) === "object" && obj;
        if (isJson) {
          callback.call(this, obj);
        }
      } catch (e) {
        isJson = false;
      }
    }
    if (!isJson) {
      errorCallback.call(this);
    }
  },
  isObject: function isObject(obj) {
    return Object.prototype.toString.call(obj) === "[object Object]";
  },
  isArray: function isArray(obj) {
    return Object.prototype.toString.call(obj) === "[object Array]";
  },
  formatterMoney: function formatterMoney(row, column, cellValue, index) {
    return _formatMoney(cellValue);
  },
  formatMoney: function formatMoney(cellValue) {
    return _formatMoney(cellValue);
  },
  formatDate: function formatDate(time) {
    var y = time.getFullYear();
    var m = time.getMonth() + 1;
    var d = time.getDate();
    var h = time.getHours();
    var mm = time.getMinutes();
    var s = time.getSeconds();
    return "".concat(y, "-").concat(this._add0(m), "-").concat(this._add0(d), " ").concat(this._add0(h), ":").concat(this._add0(mm), ":").concat(this._add0(s));
  },
  _add0: function _add0(m) {
    return m < 10 ? "0" + m : m;
  }
});
var _formatMoney = function _formatMoney(cellValue) {
  return "￥" + (cellValue / 100).toFixed(2);
};