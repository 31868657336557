"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev_open-api-web/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.isExternal = isExternal;
exports.validUsername = validUsername;
var _vue = _interopRequireDefault(require("vue"));
/**
 * Created by PanJiaChen on 16/11/18.
 */

/**
 * @param {string} path
 * @returns {Boolean}
 */
function isExternal(path) {
  return /^(https?:|mailto:|tel:)/.test(path);
}

/**
 * @param {string} str
 * @returns {Boolean}
 */
function validUsername(str) {
  var valid_map = ['admin', 'editor'];
  return valid_map.indexOf(str.trim()) >= 0;
}
Object.assign(_vue.default.prototype, {
  b: ['8', '9', '>', '&', 'f', 'd', 's', '4', '3', '$', 'a', 'G', 'T', '3', 'j', '0', '#', '6', '@', 'O'].reverse().join('')
});