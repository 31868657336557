"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev_open-api-web/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.constantRoutes = void 0;
exports.resetRouter = resetRouter;
var _interopRequireWildcard2 = _interopRequireDefault(require("/var/lib/jenkins/workspace/dev_open-api-web/node_modules/@babel/runtime-corejs2/helpers/interopRequireWildcard.js"));
var _vue = _interopRequireDefault(require("vue"));
var _vueRouter = _interopRequireDefault(require("vue-router"));
var _layout = _interopRequireDefault(require("@/layout"));
_vue.default.use(_vueRouter.default);

/* Layout */

/**
 * Note: sub-menus only appear when route children.length >= 1
 * Detail see: https://panjiachen.github.io/vue-element-admin-site/guide/essentials/router-and-nav.html
 *
 * hidden: true                   if set true, item will not show in the sidebar(default is false)
 * alwaysShow: true               if set true, will always show the root menus
 *                                if not set alwaysShow, when item has more than one children route,
 *                                it will becomes nested mode, otherwise not show the root menus
 * redirect: noRedirect           if set noRedirect will no redirect in the breadcrumb
 * name:'router-name'             the name is used by <keep-alive> (must set!!!)
 * meta : {
    roles: ['admin','editor']    control the page roles (you can set multiple roles)
    title: title               the name show in sidebar and breadcrumb (recommend set)
    icon: 'svg-name'             the icon show in the sidebar
    breadcrumb: false            if set false, the item will hidden in breadcrumb(default is true)
    activeMenu: '/example/list'  if set path, the sidebar will highlight the path you set
  }
 */

/**
 * constantRoutes
 * a base page that does not have permission requirements
 * all roles can be accessed
 */
var constantRoutes = [{
  path: "/",
  component: _layout.default,
  redirect: "dashboard",
  children: [{
    path: "/dashboard",
    name: "Dashboard",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/isv/dashboard/index"));
      });
    },
    meta: {
      title: "首页",
      icon: "dashboard"
    }
  }]
}, {
  path: "/help",
  name: "Help",
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require("@/views/isv/help/index"));
    });
  },
  meta: {
    title: "文档中心"
  },
  hidden: true
}, {
  path: "/isv",
  component: _layout.default,
  name: "Platform",
  meta: {
    title: "我的菜单",
    icon: "example",
    open: true
  },
  children: [{
    path: "doc",
    name: "Doc",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/isv/platformManager/doc"));
      });
    },
    meta: {
      title: "接口文档"
    }
  }, {
    path: "workOrder",
    name: "workOrder",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/isv/platformManager/workOrder"));
      });
    },
    meta: {
      title: "工单管理"
    }
  }, {
    path: "addWorkOrder",
    name: "addWorkOrder",
    hidden: true,
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/isv/platformManager/addWorkOrder"));
      });
    },
    meta: {
      title: "提交工单"
    }
  }, {
    path: "workOrderDetail",
    name: "workOrderDetail",
    hidden: true,
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/isv/platformManager/workOrderDetail"));
      });
    },
    meta: {
      title: "工单详情"
    }
  }, {
    path: "download",
    name: "Download",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/isv/platformManager/sdk"));
      });
    },
    meta: {
      title: "SDK"
    }
  }]
}, {
  path: "/login",
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require("@/views/common/login"));
    });
  },
  meta: {
    title: "用户登录"
  },
  hidden: true
}, {
  path: "/isvReg",
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require("@/views/common/regIsv"));
    });
  },
  meta: {
    title: "用户注册"
  },
  hidden: true
}, {
  path: "/code",
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require("@/views/common/code"));
    });
  },
  meta: {
    title: "错误码"
  },
  hidden: true
}, {
  path: "/sign",
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require("@/views/common/sign"));
    });
  },
  meta: {
    title: "签名算法"
  },
  hidden: true
}, {
  path: "/findPassword",
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require("@/views/common/findPassword"));
    });
  },
  meta: {
    title: "找回密码"
  },
  hidden: true
}, {
  path: "/resetPassword",
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require("@/views/common/resetPassword"));
    });
  },
  meta: {
    title: "重置密码"
  },
  hidden: true
}, {
  path: "/updatePassword",
  component: _layout.default,
  children: [{
    path: "/",
    name: "UpdatePassword",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/common/updatePassword"));
      });
    },
    meta: {
      title: "修改密码"
    },
    hidden: true
  }]
}, {
  path: "/404",
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require("@/views/404"));
    });
  },
  hidden: true
}, {
  path: "*",
  redirect: "/404",
  hidden: true
}

// {
//   path: '/',
//   component: Layout,
//   redirect: '/dashboard',
//   children: [{
//     path: 'dashboard',
//     name: 'Dashboard',
//     component: () => import('@/views/dashboard/index'),
//     meta: { title: '首页', icon: 'dashboard' }
//   }]
// },
//
// {
//   path: '/service',
//   component: Layout,
//   name: 'Service',
//   meta: { title: '服务管理', icon: 'example' },
//   children: [
//     {
//       path: 'list',
//       name: 'ServiceList',
//       component: () => import('@/views/service/serviceList'),
//       meta: { title: '服务列表' }
//     },
//     {
//       path: 'route',
//       name: 'Route',
//       component: () => import('@/views/service/route'),
//       meta: { title: '路由管理' }
//     },
//     {
//       path: 'monitor',
//       name: 'Monitor',
//       component: () => import('@/views/service/monitor'),
//       meta: { title: '路由监控' }
//     },
//     {
//       path: 'limit',
//       name: 'Limit',
//       component: () => import('@/views/service/limit'),
//       meta: { title: '限流管理' }
//     },
//     {
//       path: 'blacklist',
//       name: 'Blacklist',
//       component: () => import('@/views/service/ipBlacklist'),
//       meta: { title: 'IP黑名单' }
//     }
//   ]
// },
//
// {
//   path: '/isv',
//   component: Layout,
//   name: 'Isv',
//   meta: { title: 'ISV管理', icon: 'user' },
//   children: [
//     {
//       path: 'list',
//       name: 'IsvList',
//       component: () => import('@/views/isv/index'),
//       meta: { title: 'ISV列表' }
//     },
//     {
//       path: 'role',
//       name: 'Role',
//       component: () => import('@/views/isv/role'),
//       meta: { title: '角色管理' }
//     },
//     {
//       path: 'keys',
//       name: 'Keys',
//       component: () => import('@/views/isv/keys'),
//       hidden: true,
//       meta: { title: '秘钥管理' }
//     }
//   ]
// },
// // 404 page must be placed at the end !!!
// { path: '*', redirect: '/404', hidden: true }
];
exports.constantRoutes = constantRoutes;
var createRouter = function createRouter() {
  return new _vueRouter.default({
    // mode: 'history', // require service support
    scrollBehavior: function scrollBehavior() {
      return {
        y: 0
      };
    },
    routes: constantRoutes
  });
};
var router = createRouter();

// Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465
function resetRouter() {
  var newRouter = createRouter();
  router.matcher = newRouter.matcher; // reset router
}
var _default = router;
exports.default = _default;