var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "navbar" },
    [
      _c("hamburger", {
        staticClass: "hamburger-container",
        attrs: { "is-active": _vm.sidebar.opened },
        on: { toggleClick: _vm.toggleSideBar },
      }),
      _vm._v(" "),
      _c("breadcrumb", { staticClass: "breadcrumb-container" }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "right-menu" },
        [
          _c(
            "el-dropdown",
            { attrs: { trigger: "click" }, on: { command: _vm.handleCommand } },
            [
              _c("el-avatar", {
                staticClass: "user-head",
                attrs: {
                  shape: "square",
                  size: "medium",
                  icon: "el-icon-user-solid",
                },
              }),
              _vm._v(" "),
              _c(
                "el-dropdown-menu",
                { attrs: { slot: "dropdown" }, slot: "dropdown" },
                [
                  _c(
                    "el-dropdown-item",
                    { attrs: { command: _vm.onResetPwd } },
                    [
                      _c("span", { on: { click: _vm.onResetPwd } }, [
                        _vm._v("修改密码"),
                      ]),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-dropdown-item",
                    { attrs: { command: _vm.doLogout, divided: "" } },
                    [
                      _c(
                        "span",
                        {
                          staticStyle: { display: "block" },
                          on: { click: _vm.doLogout },
                        },
                        [_vm._v("退出")]
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "navbar-div" },
        [
          _c(
            "router-link",
            { attrs: { target: "_blank", to: "/help" } },
            [
              _c(
                "el-button",
                { attrs: { type: "text", icon: "el-icon-s-opportunity" } },
                [_vm._v("文档中心")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }