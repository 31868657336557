"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev_open-api-web/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("mavon-editor/dist/css/index.css");
var _DocView = _interopRequireDefault(require("@/components/DocView"));
var _Docdebug = _interopRequireDefault(require("@/components/Docdebug"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    docView: _DocView.default,
    docdebug: _Docdebug.default
  },
  data: function data() {
    return {
      active: 'info',
      docVO: {
        appId: '',
        gatewayUrl: '',
        urlProd: '',
        urlTest: '',
        menuProjects: []
      },
      item: null,
      filterText: '',
      defaultProps: {
        children: 'children',
        label: 'label'
      }
    };
  },
  watch: {
    filterText: function filterText(val) {
      this.$refs.tree.filter(val);
    }
  },
  created: function created() {
    this.loadTree();
  },
  methods: {
    loadTree: function loadTree() {
      this.get('/portal/isv/getDocMenus', {}, function (resp) {
        this.docVO = resp.data;
      });
    },
    // 树搜索
    filterNode: function filterNode(value, data) {
      if (!value) return true;
      return JSON.stringify(data).indexOf(value) !== -1;
    },
    onDocSelect: function onDocSelect(currentNode, beforeNode) {
      this.showDoc(currentNode);
    },
    showDoc: function showDoc(node) {
      if (node.id) {
        this.get('/portal/isv/getDocItem', {
          id: node.id
        }, function (resp) {
          this.item = resp.data;
        });
      }
    }
  }
};
exports.default = _default;