var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-table",
    {
      attrs: {
        data: _vm.data,
        border: "",
        "row-key": "id",
        "default-expand-all": "",
        "tree-props": { children: "refs", hasChildren: "hasChildren" },
        "cell-style": _vm.cellStyleSmall(),
        "header-cell-style": _vm.headCellStyleSmall(),
        "empty-text": "无参数",
      },
    },
    [
      _c("el-table-column", {
        attrs: { prop: "name", label: "名称", width: "250" },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (scope) {
              return [
                _c("span", { class: { required: scope.row.required } }, [
                  _vm._v(_vm._s(scope.row.name)),
                ]),
              ]
            },
          },
        ]),
      }),
      _vm._v(" "),
      _c("el-table-column", {
        attrs: { prop: "type", label: "类型", width: "100" },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (scope) {
              return [
                _c("span", [_vm._v(_vm._s(scope.row.type))]),
                _vm._v(" "),
                _c(
                  "span",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value:
                          scope.row.type === "array" && scope.row.elementType,
                        expression:
                          "scope.row.type === 'array' && scope.row.elementType",
                      },
                    ],
                  },
                  [
                    _c(
                      "el-tooltip",
                      {
                        attrs: {
                          effect: "dark",
                          content: "元素类型：" + scope.row.elementType,
                          placement: "top",
                        },
                      },
                      [_c("i", { staticClass: "el-icon-info" })]
                    ),
                  ],
                  1
                ),
              ]
            },
          },
        ]),
      }),
      _vm._v(" "),
      _c("el-table-column", {
        attrs: { prop: "paramExample", label: "参数值" },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (scope) {
              return [
                scope.row.type !== "object"
                  ? _c(
                      "el-form",
                      {
                        ref: "req_form_example_" + scope.row.id,
                        staticStyle: { display: "inline-block" },
                        attrs: {
                          model: scope.row,
                          rules: _vm.buildParamRules(scope.row),
                          size: "mini",
                        },
                      },
                      [
                        _c(
                          "el-form-item",
                          {
                            staticClass: "table-control",
                            attrs: { prop: "paramExample", "label-width": "0" },
                          },
                          [
                            scope.row.type === "file" ||
                            scope.row.elementType === "file"
                              ? _c(
                                  "el-upload",
                                  {
                                    attrs: {
                                      action: "",
                                      multiple: false,
                                      "auto-upload": false,
                                      "on-change": function (file, fileList) {
                                        return _vm.onSelectFile(
                                          file,
                                          fileList,
                                          scope.row
                                        )
                                      },
                                      "on-remove": function (file, fileList) {
                                        return _vm.onSelectFile(
                                          file,
                                          fileList,
                                          scope.row
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      "el-button",
                                      {
                                        staticClass: "choose-file",
                                        attrs: {
                                          slot: "trigger",
                                          type: "primary",
                                        },
                                        slot: "trigger",
                                      },
                                      [_vm._v("选择文件")]
                                    ),
                                  ],
                                  1
                                )
                              : _c("el-input", {
                                  attrs: {
                                    placeholder: "参数值",
                                    clearable: "",
                                  },
                                  model: {
                                    value: scope.row.paramExample,
                                    callback: function ($$v) {
                                      _vm.$set(scope.row, "paramExample", $$v)
                                    },
                                    expression: "scope.row.paramExample",
                                  },
                                }),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
              ]
            },
          },
        ]),
      }),
      _vm._v(" "),
      _c("el-table-column", { attrs: { prop: "description", label: "描述" } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }