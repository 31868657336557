"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev_open-api-web/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _objectSpread2 = _interopRequireDefault(require("/var/lib/jenkins/workspace/dev_open-api-web/node_modules/@babel/runtime-corejs2/helpers/objectSpread2.js"));
var _vuex = require("vuex");
var _Breadcrumb = _interopRequireDefault(require("@/components/Breadcrumb"));
var _Hamburger = _interopRequireDefault(require("@/components/Hamburger"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    Breadcrumb: _Breadcrumb.default,
    Hamburger: _Hamburger.default
  },
  computed: (0, _objectSpread2.default)({}, (0, _vuex.mapGetters)(['sidebar', 'avatar'])),
  methods: {
    toggleSideBar: function toggleSideBar() {
      this.$store.dispatch('app/toggleSideBar');
    },
    handleCommand: function handleCommand(command) {
      command();
    },
    onResetPwd: function onResetPwd() {
      this.goRoute('/updatePassword');
    },
    doLogout: function doLogout() {
      this.logout();
      // this.$router.push(`/login?redirect=${this.$route.fullPath}`)
    }
  }
};
exports.default = _default;