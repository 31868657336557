var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "el-form",
        {
          ref: "loginForm",
          staticClass: "center-form",
          attrs: {
            model: _vm.loginForm,
            rules: _vm.loginRules,
            "auto-complete": "on",
          },
          nativeOn: {
            submit: function ($event) {
              $event.preventDefault()
            },
          },
        },
        [
          _c("div", { staticClass: "title-container" }, [
            _c("h3", { staticClass: "title" }, [_vm._v("开放平台登录")]),
          ]),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { prop: "account" } },
            [
              _c("el-input", {
                attrs: {
                  placeholder: "账号",
                  "prefix-icon": "el-icon-user",
                  "auto-complete": "on",
                },
                model: {
                  value: _vm.loginForm.account,
                  callback: function ($$v) {
                    _vm.$set(_vm.loginForm, "account", $$v)
                  },
                  expression: "loginForm.account",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { prop: "password" } },
            [
              _c("el-input", {
                attrs: {
                  type: "password",
                  placeholder: "登录密码",
                  "prefix-icon": "el-icon-lock",
                  "auto-complete": "on",
                },
                model: {
                  value: _vm.loginForm.password,
                  callback: function ($$v) {
                    _vm.$set(_vm.loginForm, "password", $$v)
                  },
                  expression: "loginForm.password",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              staticStyle: { width: "100%" },
              attrs: {
                loading: _vm.loading,
                type: "primary",
                "native-type": "submit",
              },
              on: { click: _vm.handleLogin },
            },
            [_vm._v("登 录")]
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "footer" },
            [
              _c(
                "el-link",
                {
                  attrs: { type: "primary", underline: false },
                  on: { click: _vm.onReg },
                },
                [_vm._v("注册新账号")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }