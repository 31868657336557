"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _elementUi = require("element-ui");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  data: function data() {
    return {
      dialogVisible: false,
      defaultProps: {
        children: "children",
        label: "name"
      },
      dataForm: {
        type: 1,
        resourceList: []
      },
      appList: []
    };
  },
  computed: {
    resourceId: function resourceId() {
      return dataForm.resourceList.map();
    }
  },
  mounted: function mounted() {
    var _this = this;
    this.post("/api/route.list", {}, function (res) {
      _this.appList = res.data;
    });
  },
  methods: {
    submitForm: function submitForm(formName) {
      var _this2 = this;
      this.$refs[formName].validate(function (valid) {
        if (valid) {
          console.log(_this2.dataForm, "");
          _this2.post("/api/ticket", _this2.dataForm, function (data) {
            console.log(data, "gg");
            _this2.$router.push({
              path: "/isv/workOrder"
            });
            _elementUi.Message.success("工单提交成功!");
          }, function () {
            _elementUi.Message.error("工单提交失败!");
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    resetForm: function resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    resourceTree: function resourceTree(_, obj) {
      this.dataForm.resourceList = obj.checkedNodes.filter(function (v) {
        return !v.children;
      }).map(function (v) {
        return v.id;
      });
    }
  }
};
exports.default = _default;