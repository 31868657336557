"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _elementUi = require("element-ui");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  data: function data() {
    return {
      searchFormData: {
        pageIndex: 1,
        pageSize: 10
      },
      pageInfo: {
        list: [],
        total: 0
      },
      isvKeysDialogVisible: false,
      isvKeysFormData: {
        appKey: "",
        secret: "",
        publicKeyIsv: "",
        privateKeyIsv: "",
        publicKeyPlatform: "",
        privateKeyPlatform: "",
        signType: "",
        userId: 0
      }
    };
  },
  created: function created() {
    this.loadTable();
  },
  methods: {
    loadTable: function loadTable() {
      this.get("/api/ticket/page", this.searchFormData, function (resp) {
        this.pageInfo.list = resp.data.rows;
        this.pageInfo.total = resp.data.total;
      });
    },
    onDetail: function onDetail(row) {
      this.$router.push({
        path: "/isv/workOrderDetail",
        query: {
          uuid: row.id
        }
      });
    },
    /* 更新状态 */updateStatus: function updateStatus(info, status) {
      var _this = this;
      this.$put("/api/ticket", {
        id: info.id,
        status: status
      }, function (resp) {
        console.log(resp, "m");
        _elementUi.Message.success("状态更新成功!!");
        _this.loadTable();
      }, function () {
        _elementUi.Message.error("更改失败!!");
      });
    },
    deleteRow: function deleteRow(row) {
      var _this2 = this;
      this.$delete("/api/ticket", {
        id: row.id
      }, function (resp) {
        _this2.loadTable();
      }, function () {});
    },
    submitWorkOrder: function submitWorkOrder() {
      this.$router.push({
        path: "/isv/addWorkOrder"
      });
    },
    onSizeChange: function onSizeChange(size) {
      this.searchFormData.pageSize = size;
      this.loadTable();
    },
    onPageIndexChange: function onPageIndexChange(pageIndex) {
      this.searchFormData.pageIndex = pageIndex;
      this.loadTable();
    }
  }
};
exports.default = _default;