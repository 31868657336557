var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("h2", [_vm._v(_vm._s(_vm.docInfo.summary))]),
      _vm._v(" "),
      _c(
        "el-table",
        {
          attrs: {
            data: [
              {
                methodLabel: "接口名(method)",
                methodValue: _vm.docInfo.name,
                versionLabel: "版本号(version)",
                versionValue: _vm.docInfo.version,
              },
            ],
            border: "",
            "cell-style": _vm.baseInfoCellStyle,
            "show-header": false,
          },
        },
        [
          _c("el-table-column", {
            attrs: { prop: "methodLabel", align: "center", width: "130" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", { staticClass: "api-info" }, [
                      _vm._v(_vm._s(scope.row.methodLabel)),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", { attrs: { prop: "methodValue" } }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { prop: "versionLabel", align: "center", width: "130" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", { staticClass: "api-info" }, [
                      _vm._v(_vm._s(scope.row.versionLabel)),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { prop: "versionValue", width: "120" },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("h3", [_vm._v("接口描述")]),
      _vm._v(" "),
      _c("div", { staticClass: "doc-overview" }, [
        _vm._v(_vm._s(_vm.docInfo.description || _vm.docInfo.title)),
      ]),
      _vm._v(" "),
      _c("h3", [_vm._v("请求地址")]),
      _vm._v(" "),
      _c(
        "el-table",
        {
          attrs: {
            data: [
              {
                envLabel: "环境",
                envValue: "正式环境",
                urlLabel: "请求地址",
                urlValue: _vm.urlProd,
              },
            ],
            border: "",
            "cell-style": _vm.baseInfoCellStyle,
            "show-header": false,
          },
        },
        [
          _c("el-table-column", {
            attrs: { align: "center", width: "100" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", { staticClass: "api-info" }, [
                      _vm._v(_vm._s(scope.row.envLabel)),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", { attrs: { prop: "envValue", width: "140" } }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { align: "center", width: "100" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", { staticClass: "api-info" }, [
                      _vm._v(_vm._s(scope.row.urlLabel)),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", { attrs: { prop: "urlValue" } }),
        ],
        1
      ),
      _vm._v(" "),
      _c("h3", [_vm._v("请求方法")]),
      _vm._v(" "),
      _c("div", { staticClass: "doc-request-method" }, [
        _vm._v(
          "\n    " +
            _vm._s(
              _vm.docInfo.httpMethodList &&
                _vm.docInfo.httpMethodList.join(" / ").toUpperCase()
            ) +
            "\n  "
        ),
      ]),
      _vm._v(" "),
      _c("h2", [_vm._v("请求参数")]),
      _vm._v(" "),
      _c("h3", [_vm._v("公共请求参数")]),
      _vm._v(" "),
      _c(
        "el-table",
        {
          attrs: {
            data: _vm.commonParams,
            "cell-style": _vm.cellStyleSmall(),
            "header-cell-style": _vm.headCellStyleSmall(),
            border: "",
          },
        },
        [
          _c("el-table-column", {
            attrs: { prop: "name", label: "名称", width: "200" },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { prop: "type", label: "类型", width: "100" },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { prop: "must", label: "必须", width: "60" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", { class: scope.row.must ? "danger" : "" }, [
                      _vm._v(_vm._s(scope.row.must ? "是" : "否")),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { prop: "description", label: "描述" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v(
                      "\n        " +
                        _vm._s(scope.row.description) +
                        "\n        "
                    ),
                    scope.row.name === "sign"
                      ? _c(
                          "span",
                          [
                            _vm._v("，\n          "),
                            _c(
                              "router-link",
                              {
                                attrs: {
                                  target: "_blank",
                                  to: "/help?id=sign",
                                },
                              },
                              [
                                _c("el-button", { attrs: { type: "text" } }, [
                                  _vm._v("签名算法介绍"),
                                ]),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { prop: "example", label: "示例值" },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("h3", [_vm._v("业务请求参数")]),
      _vm._v(" "),
      _c("parameter-table", { attrs: { data: _vm.docInfo.requestParameters } }),
      _vm._v(" "),
      _c("h2", [_vm._v("响应参数")]),
      _vm._v(" "),
      _c("h3", [_vm._v("公共响应参数")]),
      _vm._v(" "),
      _c(
        "el-table",
        {
          attrs: {
            data: _vm.commonResult,
            "cell-style": _vm.cellStyleSmall(),
            "header-cell-style": _vm.headCellStyleSmall(),
            border: "",
          },
        },
        [
          _c("el-table-column", {
            attrs: { prop: "name", label: "名称", width: "200" },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { prop: "type", label: "类型", width: "100" },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { prop: "description", label: "描述" },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { prop: "example", label: "示例值" },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("h3", [_vm._v("业务响应参数")]),
      _vm._v(" "),
      _c("parameter-table", {
        attrs: { data: _vm.docInfo.responseParameters },
      }),
      _vm._v(" "),
      _c("h3", [_vm._v("响应示例")]),
      _vm._v(" "),
      _c("pre", { staticClass: "normal-text" }, [
        _vm._v(_vm._s(JSON.stringify(_vm.responseSuccessExample, null, 4))),
      ]),
      _vm._v(" "),
      _c("h3", [_vm._v("错误示例")]),
      _vm._v(" "),
      _c("pre", { staticClass: "normal-text" }, [
        _vm._v(_vm._s(JSON.stringify(_vm.responseErrorExample, null, 4))),
      ]),
      _vm._v(" "),
      _c("h2", [_vm._v("业务错误码")]),
      _vm._v(" "),
      _c(
        "router-link",
        { attrs: { target: "_blank", to: "/code" } },
        [_c("el-button", { attrs: { type: "text" } }, [_vm._v("公共错误码")])],
        1
      ),
      _vm._v(" "),
      _c(
        "el-table",
        {
          attrs: {
            data: _vm.docInfo.bizCodeList,
            border: "",
            "cell-style": _vm.cellStyleSmall(),
            "header-cell-style": _vm.headCellStyleSmall(),
          },
        },
        [
          _c("el-table-column", {
            attrs: { prop: "code", label: "sub_code（错误码）", width: "300" },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { prop: "msg", label: "sub_msg（错误描述）" },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { prop: "solution", label: "解决方案" },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }