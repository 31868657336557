"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = {
  name: 'ParameterTable',
  props: {
    data: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    tree: {
      type: Boolean,
      default: true
    }
  }
};
exports.default = _default2;