"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev_open-api-web/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getInfo = getInfo;
exports.login = login;
exports.logout = logout;
var _request = _interopRequireDefault(require("@/utils/request"));
function login(data) {
  return (0, _request.default)({
    url: '/user/login',
    method: 'post',
    data: data
  });
}
function getInfo(token) {
  return (0, _request.default)({
    url: '/user/info',
    method: 'get',
    params: {
      token: token
    }
  });
}
function logout() {
  return (0, _request.default)({
    url: '/user/logout',
    method: 'post'
  });
}