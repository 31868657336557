"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev_open-api-web/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es7.object.values");
require("core-js/modules/es6.object.keys");
require("core-js/modules/es6.regexp.constructor");
require("core-js/modules/es6.regexp.match");
require("core-js/modules/es6.string.starts-with");
require("core-js/modules/es6.regexp.split");
require("core-js/modules/es6.function.name");
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.string.iterator");
var _ParameterTableEdit = _interopRequireDefault(require("@/components/ParameterTableEdit"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

var privateKeyStoreKey = 'sop.sendbox.privateKey';
var _default2 = {
  name: 'Docdebug',
  components: {
    ParameterTableEdit: _ParameterTableEdit.default
  },
  props: {
    item: {
      type: Object,
      default: function _default() {}
    },
    appId: {
      type: String,
      default: ''
    },
    gatewayUrl: {
      type: String,
      default: ''
    }
  },
  data: function data() {
    return {
      baseInfoCellStyle: function baseInfoCellStyle(row) {
        if (row.columnIndex === 0 || row.columnIndex === 2) {
          return {
            padding: '5px 0',
            background: '#f5f7fa'
          };
        } else {
          return {
            padding: '5px 0'
          };
        }
      },
      configFormRules: {
        appKey: [{
          required: true,
          message: '请填写AppId',
          trigger: 'blur'
        }],
        privateKey: [{
          required: true,
          message: '请填写应用私钥',
          trigger: 'blur'
        }],
        url: [{
          required: true,
          message: '请填写URL',
          trigger: 'blur'
        }]
      },
      configFormData: {
        url: '',
        appKey: '',
        privateKey: '',
        token: ''
      },
      httpMethod: '',
      docInfo: {
        summary: '',
        name: '',
        version: '',
        multiple: false,
        uploadRequest: false,
        httpMethodList: [],
        requestParameters: [],
        responseParameters: [],
        bizCodes: []
      },
      uploadFiles: [],
      resultActive: 'resultContent',
      resultShow: false,
      reqInfo: '',
      resultContent: ''
    };
  },
  watch: {
    item: function item(newVal) {
      this.initItem(newVal);
    },
    appId: function appId(newVal) {
      this.configFormData.appKey = newVal;
    },
    gatewayUrl: function gatewayUrl(url) {
      this.configFormData.url = url;
    }
  },
  created: function created() {
    var privateKey = this.getAttr(privateKeyStoreKey);
    if (privateKey) {
      this.configFormData.privateKey = privateKey;
    }
  },
  methods: {
    send: function send() {
      var _this = this;
      this.$refs.configForm.validate(function (valid) {
        if (valid) {
          // 验证表格参数
          var promiseRequestArr = _this.validateTable(_this.docInfo.requestParameters, ['req_form_example_']);
          Promise.all(promiseRequestArr).then(function (validArr) {
            // 到这里来表示全部内容校验通过
            _this.doSend();
          }).catch(function (e) {
            // this.tipError('请完善表单内容')
          }); // 加上这个控制台不会报Uncaught (in promise)
        }
      });
    },
    doSend: function doSend() {
      var bizContent = this.buildParamData(this.docInfo.requestParameters);
      var data = {
        gatewayUrl: this.configFormData.url,
        appId: this.configFormData.appKey,
        privateKey: this.configFormData.privateKey,
        token: this.configFormData.token,
        method: this.docInfo.name,
        version: this.docInfo.version,
        httpMethod: this.httpMethod,
        bizContent: JSON.stringify(bizContent)
      };
      var files = this.buildFiles(this.docInfo.requestParameters);
      var isForm = this.httpMethod.toUpperCase() === 'POST';
      this.request(this.httpMethod, '/sandbox/test_v2', data, {}, false, isForm, files, function (error, response) {
        this.resultShow = true;
        this.resultActive = 'resultContent';
        var status = response.statusCode || response.status;
        if (!error && status === 200) {
          this.successHandler(response);
        } else {
          console.log(error);
          this.$message.error('请求异常，请查看日志');
        }
      });
    },
    validateTable: function validateTable(arr, refPrefixArr) {
      var _this2 = this;
      var $refs = this.$refs.paramTableRef.$refs;
      var promiseArr = [];
      var _loop = function _loop() {
        var row = arr[i];
        var id = row.id;
        refPrefixArr.forEach(function (refPrefix) {
          var ref = $refs[refPrefix + id];
          if (ref) {
            promiseArr.push(ref.validate());
          }
        });
        var children = arr[i].children;
        if (children && children.length > 0) {
          var childrenPromiseArr = _this2.validateTable(children, refPrefixArr);
          promiseArr = promiseArr.concat(childrenPromiseArr);
        }
      };
      for (var i = 0; i < arr.length; i++) {
        _loop();
      }
      return promiseArr;
    },
    successHandler: function successHandler(response) {
      this.setReqInfo(response);
      this.setRespInfo(response);
    },
    setReqInfo: function setReqInfo(response) {
      var headers = response.headers;
      if (headers) {
        var html = [];
        html.push('【请求参数】：' + decodeURIComponent(headers['sendbox-params']));
        html.push('【待签名内容】：' + decodeURIComponent(headers['sendbox-beforesign']));
        html.push('【签名(sign)】：' + decodeURIComponent(headers['sendbox-sign']));
        this.reqInfo = html.join('\r\n');
      }
    },
    setRespInfo: function setRespInfo(response) {
      var headers = response.headers;
      var targetHeadersString = headers['target-response-headers'] || '{}';
      var targetHeaders = JSON.parse(targetHeadersString);
      var contentType = targetHeaders['content-type'] || '';
      var contentDisposition = targetHeaders['content-disposition'] || '';
      if (contentType.indexOf('stream') > -1 || contentDisposition.indexOf('attachment') > -1) {
        var filename = this.getDispositionFilename(contentDisposition);
        this.downloadFile(filename, response.raw);
      } else {
        var body = response.body || response.data;
        this.resultContent = JSON.stringify(body, null, 4);
      }
    },
    downloadFile: function downloadFile(filename, buffer) {
      var url = window.URL.createObjectURL(new Blob([buffer]));
      var link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', filename);
      document.body.appendChild(link);
      link.click();
    },
    getDispositionFilename: function getDispositionFilename(disposition) {
      var dispositionArr = disposition.split(';');
      for (var i = 0; i < dispositionArr.length; i++) {
        var item = dispositionArr[i].trim();
        // filename="xx"
        if (item.toLowerCase().startsWith('filename')) {
          var result = item.match(new RegExp('filename="(.*?)"', 'i'));
          return result ? result[1] : '';
        }
      }
    },
    resetResult: function resetResult() {
      this.reqInfo = '';
      this.resultContent = '';
      this.resultShow = false;
    },
    initItem: function initItem(item) {
      if (item) {
        this.setData(item);
      }
    },
    setData: function setData(data) {
      this.resetResult();
      this.docInfo = data;
      this.httpMethod = this.docInfo.httpMethodList[0];
    },
    onSelectMultiFile: function onSelectMultiFile(file, fileList) {
      var files = [];
      fileList.forEach(function (file) {
        var rawFile = file.raw;
        files.push(rawFile);
      });
      this.uploadFiles = files;
    },
    onPrivateKeyChange: function onPrivateKeyChange() {
      this.setAttr(privateKeyStoreKey, this.configFormData.privateKey);
    },
    buildParamData: function buildParamData(params) {
      var responseJson = {};
      for (var i = 0; i < params.length; i++) {
        var row = params[i];
        if (row.in === 'header') {
          continue;
        }
        var val = void 0;
        // 如果有子节点
        if (row.refs && row.refs.length > 0) {
          var childrenValue = this.buildParamData(row.refs);
          // 如果是数组
          if (row.type === 'array') {
            val = [childrenValue];
          } else {
            val = childrenValue;
          }
        } else {
          // 单值
          val = row.paramExample;
        }
        responseJson[row.name] = val;
      }
      var isOneArray = Object.keys(responseJson).length === 1 && this.isArray(Object.values(responseJson)[0]);
      if (isOneArray) {
        return Object.values(responseJson)[0];
      }
      return responseJson;
    },
    buildFiles: function buildFiles(params) {
      var files = [];
      for (var i = 0; i < params.length; i++) {
        var row = params[i];
        // 处理文件上传
        var fileConfig = row.__file__;
        if (fileConfig) {
          files.push(fileConfig);
        }
      }
      // 全局上传
      if (this.uploadFiles.length > 0) {
        files.push({
          name: 'file',
          files: this.uploadFiles
        });
      }
      return files;
    }
  }
};
exports.default = _default2;