"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev_open-api-web/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _jsMd = _interopRequireDefault(require("js-md5"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  data: function data() {
    var _this = this;
    var validatePassword2 = function validatePassword2(rule, value, callback) {
      if (value !== _this.resetPwdData.password) {
        callback(new Error('两次密码不一致'));
      } else {
        callback();
      }
    };
    return {
      resetPwdData: {
        email: '',
        password: '',
        password2: ''
      },
      resetRules: {
        password: [{
          required: true,
          message: '请输入新密码',
          trigger: 'blur'
        }, {
          min: 6,
          message: '密码长度不能小于6位',
          trigger: 'blur'
        }],
        password2: [{
          required: true,
          trigger: 'blur',
          validator: validatePassword2
        }]
      }
    };
  },
  created: function created() {
    var query = this.$route.query;
    Object.assign(this.resetPwdData, query);
  },
  methods: {
    onReset: function onReset() {
      var _this2 = this;
      this.$refs.resetPwdForm.validate(function (valid) {
        if (valid) {
          var data = {};
          Object.assign(data, _this2.resetPwdData);
          data.password = (0, _jsMd.default)(data.password);
          _this2.get('nologin.user.password.reset', data, function (resp) {
            alert('密码重置成功');
            this.logout();
          }, function (resp) {
            return _this2.tipError(resp.msg);
          });
        }
      });
    }
  }
};
exports.default = _default;