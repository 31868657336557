var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "app-container" }, [
    _c("h3", [_vm._v("公共错误码")]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "isp-info" },
      [
        _c(
          "el-table",
          {
            attrs: {
              data: _vm.tableData,
              "row-key": "id",
              "default-expand-all": "",
              border: "",
            },
          },
          [
            _c("el-table-column", {
              attrs: { label: "code", prop: "code", width: "200" },
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: { label: "msg", prop: "msg", width: "200" },
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: {
                label: "sub_code（详细错误码）",
                prop: "sub_code",
                width: "250",
              },
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: { label: "sub_msg（详细错误信息）", prop: "sub_msg" },
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: { label: "解决方案", prop: "solution" },
            }),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }