var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container2" },
    [
      _c("el-backtop"),
      _vm._v(" "),
      _c(
        "el-container",
        [
          _c(
            "el-header",
            [
              _c(
                "el-menu",
                {
                  attrs: {
                    "default-active": _vm.activeIndex,
                    mode: "horizontal",
                    "background-color": "#545c64",
                    "text-color": "#fff",
                    "active-text-color": "#ffd04b",
                  },
                },
                [
                  _c("el-menu-item", { attrs: { index: "1" } }, [
                    _vm._v("文档中心"),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-container",
            [
              _c(
                "el-aside",
                { attrs: { width: "200px" } },
                [
                  _c(
                    "el-menu",
                    {
                      attrs: {
                        "default-openeds": _vm.openMenu,
                        "default-active": _vm.defaultActive,
                      },
                      on: { select: _vm.onMenuClick },
                    },
                    _vm._l(_vm.menus, function (item) {
                      return _c(
                        "el-submenu",
                        { key: item.path, attrs: { index: item.title } },
                        [
                          _c("template", { slot: "title" }, [
                            _c("i", { class: item.icon }),
                            _vm._v(" "),
                            _c(
                              "span",
                              { attrs: { slot: "title" }, slot: "title" },
                              [_vm._v(_vm._s(item.title))]
                            ),
                          ]),
                          _vm._v(" "),
                          _vm._l(item.children, function (child) {
                            return _c(
                              "el-menu-item",
                              { key: child.path, attrs: { index: child.path } },
                              [_vm._v(_vm._s(child.title))]
                            )
                          }),
                        ],
                        2
                      )
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-main",
                [
                  _c("mavon-editor", {
                    attrs: {
                      boxShadow: false,
                      subfield: false,
                      defaultOpen: "preview",
                      editable: false,
                      toolbarsFlag: false,
                    },
                    model: {
                      value: _vm.content,
                      callback: function ($$v) {
                        _vm.content = $$v
                      },
                      expression: "content",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }