"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.string.ends-with");
require("core-js/modules/web.dom.iterable");
var _mavonEditor = require("mavon-editor");
require("mavon-editor/dist/css/index.css");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    mavonEditor: _mavonEditor.mavonEditor
  },
  data: function data() {
    return {
      defaultActive: '',
      docId: '',
      menus: [],
      openMenu: [],
      content: '欢迎来到文档中心',
      activeIndex: '1'
    };
  },
  created: function created() {
    this.docId = this.$route.query.id;
    this.loadMenu();
  },
  methods: {
    loadMenu: function loadMenu() {
      var _this = this;
      var currentDoc = null;
      this.getFile('static/doc/isv/menu.json', function (menus) {
        _this.menus = menus;
        menus.forEach(function (item) {
          _this.openMenu.push(item.title);
          if (_this.docId) {
            var children = item.children || [];
            for (var i = 0; i < children.length; i++) {
              var child = children[i];
              if (child.path && child.path.endsWith("".concat(_this.docId, ".md"))) {
                currentDoc = child;
                _this.defaultActive = child.path;
                break;
              }
            }
          }
        });
        if (currentDoc) {
          _this.loadMarkdown(currentDoc.path);
        }
      });
    },
    onHeaderMenuSelect: function onHeaderMenuSelect(index) {},
    onMenuClick: function onMenuClick(index, path) {
      this.loadMarkdown(index);
    },
    loadMarkdown: function loadMarkdown(path) {
      var _this2 = this;
      this.getFile("".concat(path, "?q=").concat(new Date().getTime()), function (content) {
        _this2.content = content;
      });
    }
  }
};
exports.default = _default;