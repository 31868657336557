"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev_open-api-web/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _objectSpread2 = _interopRequireDefault(require("/var/lib/jenkins/workspace/dev_open-api-web/node_modules/@babel/runtime-corejs2/helpers/objectSpread2.js"));
var _elementUi = require("element-ui");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  data: function data() {
    return {
      dialogVisible: false,
      defaultProps: {
        children: "children",
        label: "name"
      },
      dataForm: {
        type: 1,
        resourceList: []
      },
      appList: []
    };
  },
  computed: {
    resourceId: function resourceId() {
      return dataForm.resourceList.map();
    }
  },
  mounted: function mounted() {
    var _this = this;
    var id = this.$route.query.uuid;
    this.post("/api/route.list", {}, function (res) {
      _this.appList = res.data.map(function (v) {
        return (0, _objectSpread2.default)((0, _objectSpread2.default)({}, v), {}, {
          disabled: true,
          children: v.children.map(function (n) {
            return (0, _objectSpread2.default)((0, _objectSpread2.default)({}, n), {}, {
              disabled: true
            });
          })
        });
      });
    });
    this.get("/api/ticket/".concat(id), {}, function (res) {
      _this.dataForm = res.data;
    });
  },
  methods: {
    resourceTree: function resourceTree(_, obj) {
      this.dataForm.resourceList = obj.checkedNodes.filter(function (v) {
        return !v.children;
      }).map(function (v) {
        return v.id;
      });
    }
  }
};
exports.default = _default;