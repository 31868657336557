"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev_open-api-web/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.function.name");
var _jsMd = _interopRequireDefault(require("js-md5"));
var _email = require("@/utils/email");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'RegIsv',
  data: function data() {
    var _this = this;
    var validatePassword2 = function validatePassword2(rule, value, callback) {
      if (value !== _this.regForm.password) {
        callback(new Error('两次密码不一致'));
      } else {
        callback();
      }
    };
    return {
      query: {},
      submited: false,
      emailUrl: '',
      regForm: {
        username: '',
        password: '',
        password2: '',
        namespace: '',
        company: '',
        type: 2
      },
      regRules: {
        username: [{
          required: true,
          message: '请填写邮箱地址',
          trigger: 'blur'
        }, {
          type: 'email',
          message: '请输入正确的邮箱地址',
          trigger: ['blur']
        }],
        password: [{
          required: true,
          message: '请输入密码',
          trigger: 'blur'
        }, {
          min: 6,
          message: '密码长度不能小于6位',
          trigger: 'blur'
        }],
        password2: [{
          required: true,
          trigger: 'blur',
          validator: validatePassword2
        }]
      },
      loading: false,
      passwordType: 'password',
      password2Type: 'password',
      regTitle: '接入方注册'
    };
  },
  created: function created() {},
  methods: {
    showPwd: function showPwd() {
      var _this2 = this;
      if (this.passwordType === 'password') {
        this.passwordType = '';
      } else {
        this.passwordType = 'password';
      }
      this.$nextTick(function () {
        _this2.$refs.password.focus();
      });
    },
    showPwd2: function showPwd2() {
      var _this3 = this;
      if (this.password2Type === 'password') {
        this.password2Type = '';
      } else {
        this.password2Type = 'password';
      }
      this.$nextTick(function () {
        _this3.$refs.password2.focus();
      });
    },
    onTabClick: function onTabClick(tab) {
      this.$router.push({
        path: "/".concat(tab.name, "Reg")
      });
    },
    goLogin: function goLogin() {
      this.goRoute('/login');
    },
    handleReg: function handleReg() {
      var _this4 = this;
      this.$refs.regForm.validate(function (valid) {
        if (valid) {
          _this4.doSubmit();
        }
      });
    },
    parseEmailUrl: function parseEmailUrl() {
      var that = this;
      (0, _email.goEmailSite)(this.regForm.username, function (url) {
        that.emailUrl = url;
      });
    },
    goEmailPage: function goEmailPage() {
      if (this.emailUrl) {
        window.open(this.emailUrl);
      }
    },
    formatEmail: function formatEmail() {
      return (0, _email.encodeEmail)(this.regForm.username);
    },
    onCaptchaSuccess: function onCaptchaSuccess(params) {
      this.doSubmit(function (data) {
        data.captcha = params;
      });
    },
    doSubmit: function doSubmit(callback) {
      var _this5 = this;
      var data = {};
      Object.assign(data, this.regForm);
      data.password = (0, _jsMd.default)(data.password);
      callback && callback.call(this, data);
      this.parseEmailUrl();
      this.post('/portal/common/regIsv', data, function (resp) {
        // 验证邮箱
        if (data.needVerifyEmail) {
          this.submited = true;
        } else {
          this.alert('注册成功', '提示', function () {
            this.goRoute("/login");
          });
        }
      }, function (resp) {
        _this5.tipError(resp.msg);
      });
    },
    useVerify: function useVerify() {
      this.$refs.verify.show();
    }
  }
};
exports.default = _default;