var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "el-form",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.submited,
              expression: "!submited",
            },
          ],
          ref: "regForm",
          staticClass: "center-form",
          attrs: { model: _vm.regForm, rules: _vm.regRules },
          nativeOn: {
            submit: function ($event) {
              $event.preventDefault()
            },
          },
        },
        [
          _c("h3", [_vm._v("接入方注册")]),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { prop: "username" } },
            [
              _c("el-input", {
                attrs: {
                  placeholder: "邮箱地址",
                  "prefix-icon": "el-icon-user",
                  maxlength: "100",
                  "show-word-limit": "",
                },
                model: {
                  value: _vm.regForm.username,
                  callback: function ($$v) {
                    _vm.$set(_vm.regForm, "username", $$v)
                  },
                  expression: "regForm.username",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { prop: "password" } },
            [
              _c("el-input", {
                attrs: {
                  type: "password",
                  placeholder: "登录密码",
                  "prefix-icon": "el-icon-lock",
                },
                model: {
                  value: _vm.regForm.password,
                  callback: function ($$v) {
                    _vm.$set(_vm.regForm, "password", $$v)
                  },
                  expression: "regForm.password",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { prop: "password2" } },
            [
              _c("el-input", {
                attrs: {
                  type: "password",
                  placeholder: "确认密码",
                  "prefix-icon": "el-icon-lock",
                },
                model: {
                  value: _vm.regForm.password2,
                  callback: function ($$v) {
                    _vm.$set(_vm.regForm, "password2", $$v)
                  },
                  expression: "regForm.password2",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              staticStyle: { width: "100%" },
              attrs: { type: "primary", "native-type": "submit" },
              on: { click: _vm.handleReg },
            },
            [_vm._v("注 册")]
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "footer" },
            [
              _vm._v("\n      已有账号，"),
              _c(
                "el-button",
                { attrs: { type: "text" }, on: { click: _vm.goLogin } },
                [_vm._v("去登录")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.submited,
              expression: "submited",
            },
          ],
          staticClass: "login-container",
        },
        [
          _c(
            "el-form",
            { staticClass: "login-form" },
            [
              _c("div", { staticClass: "title-container" }, [
                _c("h3", { staticClass: "title" }, [_vm._v("账号激活")]),
              ]),
              _vm._v(" "),
              _c(
                "el-alert",
                { staticClass: "el-alert-tip", attrs: { closable: false } },
                [
                  _c("div", { attrs: { slot: "title" }, slot: "title" }, [
                    _vm._v(
                      "\n          我们向邮箱 " +
                        _vm._s(_vm.formatEmail()) +
                        " 发送了一封含有账号激活链接的邮件。请登录邮箱查看，如长时间没有收到邮件，请检查你的垃圾邮件文件夹。\n        "
                    ),
                  ]),
                ]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.emailUrl,
                      expression: "emailUrl",
                    },
                  ],
                  staticStyle: { width: "100%", "margin-bottom": "10px" },
                  attrs: { type: "success" },
                  on: { click: _vm.goEmailPage },
                },
                [_vm._v("前往登录邮箱")]
              ),
              _vm._v(" "),
              _c("br"),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  staticStyle: { width: "100%" },
                  attrs: { type: "text" },
                  on: {
                    click: function () {
                      return _vm.goRoute("/login")
                    },
                  },
                },
                [_vm._v("前往登录页")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }