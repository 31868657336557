var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c("div", { staticClass: "open-sphere-log" }, [_vm._v("XX")]),
      _vm._v(" "),
      _c(
        "el-form",
        {
          ref: "resetPwdForm",
          staticClass: "center-form",
          attrs: { model: _vm.resetPwdData, rules: _vm.resetRules },
        },
        [
          _c("h3", [_vm._v("重置密码")]),
          _vm._v(" "),
          _c(
            "el-form-item",
            [
              _c("el-input", {
                attrs: {
                  placeholder: "邮箱",
                  "prefix-icon": "el-icon-ext-email",
                  disabled: true,
                },
                model: {
                  value: _vm.resetPwdData.email,
                  callback: function ($$v) {
                    _vm.$set(_vm.resetPwdData, "email", $$v)
                  },
                  expression: "resetPwdData.email",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { prop: "password" } },
            [
              _c("el-input", {
                attrs: {
                  type: "password",
                  placeholder: "新密码",
                  "prefix-icon": "el-icon-lock",
                },
                model: {
                  value: _vm.resetPwdData.password,
                  callback: function ($$v) {
                    _vm.$set(_vm.resetPwdData, "password", $$v)
                  },
                  expression: "resetPwdData.password",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { prop: "password2" } },
            [
              _c("el-input", {
                attrs: {
                  type: "password",
                  placeholder: "确认新密码",
                  "prefix-icon": "el-icon-lock",
                },
                model: {
                  value: _vm.resetPwdData.password2,
                  callback: function ($$v) {
                    _vm.$set(_vm.resetPwdData, "password2", $$v)
                  },
                  expression: "resetPwdData.password2",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                {
                  staticStyle: { width: "100%" },
                  attrs: { type: "primary" },
                  nativeOn: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.onReset($event)
                    },
                  },
                },
                [_vm._v("确定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }